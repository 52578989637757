
  import Vue, { VueConstructor } from "vue"
  import axios, { AxiosError, AxiosResponse } from "axios"
  import { EventBus } from "@/plugins/eventBus"
  import PermissionMixin from "@/mixins/PermissionMixin.vue"
  
  interface Permission {
    "name": string,
    "value": string,
    "disabled": boolean
  }
  
  export default (Vue as VueConstructor<Vue & InstanceType<typeof PermissionMixin>>).extend({
    name: 'UserFormUpdate',
    mixins: [
      PermissionMixin
    ],
    data: () => ({
      route: "space",
      id: null as string|null,
      formUser: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        selected_spaces: [] as number[],
        selected_permissions: [],
      },
      searchedSpaces: [] as {id: number, name: string}[],
      show: false,
      fetchedUser: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        spaces: [] as {id: number, name: string}[],
        permissions: [],
      },
      search: null,
      disabled: true,
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }),
    computed: {    
      emailRule() {
        return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
      },
      requiredRule() {
        return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
      },
      nameLengthRule() {
        return (v: string) => ((v.length > 1 && v.length < 100) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
      },
      stringLengthRule() {
        return (v: string) => ((v.length > 1 && v.length < 254) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
      },
      phoneNumberRule() {
        return (v: string) => (!v || (5 <= v.length && v.length <= 20)) || this.$vuetify.lang.t('$vuetify.invalid_length')
      },
      atLeastOneRequired() {
        return (v: Array<number>) => ((v.length > 0) || !v) || this.$vuetify.lang.t('$vuetify.required_field')
      },
      availablePermissions() {
        const permissionNames = Object.keys(this.$vuetify.lang.locales.en.permissions_names).filter((permission) => {
          // all permissions from local file, except "app-admin" and "space-users"
          return permission !== 'app-admin'
        })
        let permissions: Array<Permission> = []
  
        permissionNames.forEach(permission => {
          permissions.push({
            "name": this.$vuetify.lang.t('$vuetify.permissions_names.' + permission),
            "value": permission,
            "disabled": permission === 'app-admin' || permission === 'space-users' || this.hasPermission(permission) === false})
        })
  
        return permissions
      },
      spaces() : {id: number, name: string}[] {
        // We remove duplicates between what has been fetch on initialization and research
        let uniqSpaceIds = [] as number[]
        let concatSpaces = this.fetchedUser.spaces.concat(this.searchedSpaces) as {id: number, name: string}[]
        const uniqueSpaces = concatSpaces.filter((element: {id: number, name: string}) => {
          const isDuplicate = uniqSpaceIds.includes(element.id)
          if (!isDuplicate) {
            uniqSpaceIds.push(element.id)
            return true
          }
          return false
        })
        return uniqueSpaces
      }
    },
    mounted() {
      this.getSpaces()
      this.getUser(Number(this.$route.params.id))
    },
    watch: {
      search(val) {
        this.getSpaces(val)
      },
      $route (to) {
        this.getUser(to.params.id)
        this.id = to.params.id
      }
    },
    methods: {
      updateUser() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          let body: {[k: string]: string|string[]|boolean} = {}

          // compare the form data against the fetched data
          if (this.formUser.firstname !== this.fetchedUser.firstname) {
            body.firstname = this.formUser.firstname
          }
          if (this.formUser.lastname !== this.fetchedUser.lastname) {
            body.lastname = this.formUser.lastname
          }
          if (this.formUser.email !== this.fetchedUser.email) {
            body.email = this.formUser.email
          }
          if (this.formUser.phone !== this.fetchedUser.phone) {
            body.phone = this.formUser.phone
          }
          if (this.formUser.password !== "") {
            body.password = this.formUser.password
          }
          if (this.formUser.selected_permissions !== this.fetchedUser.permissions) {
            body.api_roles = this.formUser.selected_permissions
          }

          // add the spaces found in the fetched data but missing in the form
          // We store the ids of the spaces in an array for easier add/remove qualification
          const fetchedSpacesIds = this.fetchedUser.spaces.map(space => space.id)
          
          // We keep spaces found in the form but not in fetched data
          const spacesToAdd = this.formUser.selected_spaces.filter(id => !fetchedSpacesIds.includes(id))
          
          // If spaces are found, we add them to the user
          if (spacesToAdd.length > 0) {
            Object.assign(body, {add_spaces: spacesToAdd})
          }

          // We keep spaces found in fetched data but not in the form
          const spacesToRemove = fetchedSpacesIds.filter(id => !this.formUser.selected_spaces.includes(id))
          // If spaces are found, we remove them from the user
          if (spacesToRemove.length > 0) {
            Object.assign(body, {remove_spaces: spacesToRemove})
          }
          axios
            .put('/v1/space/users', {
              [this.$route.params.id]: body
            })
            .then(() => {
              EventBus.$emit('listParagonDataReload')
              EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.user_successfully_updated')})
            })
            .catch((error: AxiosError) => {
              EventBus.$emit('snackbar',{axiosError: error})
            }).finally(() => {
              this.formUser.password = ''
              this.disabled = true
            })
        }
      },
      getUser(id: number) {
        axios
          .get(`/v1/space/users/${id}`)
          .then((response: AxiosResponse) => {
            this.formUser.firstname = response.data['firstname']
            this.formUser.lastname = response.data['lastname']
            this.formUser.email = response.data['email']
            this.formUser.phone = response.data['phone']
            this.formUser.selected_spaces = response.data['spaces'].map((space:{ id: string, name: string}) => space.id)
            this.formUser.selected_permissions = response.data['permissions']

            // the user's data to keep track of modifications
            this.fetchedUser.firstname = response.data['firstname']
            this.fetchedUser.lastname = response.data['lastname']
            this.fetchedUser.email = response.data['email']
            this.fetchedUser.phone = response.data['phone']
            this.fetchedUser.spaces = response.data['spaces']
            this.fetchedUser.permissions = response.data['permissions']
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
          .finally(() =>{
            this.disabled = true
          })
      },
      getSpaces(search = null) {
        let url = "/v1/agencies"
        url += search !== null ? `?page=1&name=${search}` : ""
        axios
          .get(url)
          .then((response: AxiosResponse) => {
            this.searchedSpaces = response.data
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
      },
    }
  })
